import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "login-1",
    path: `${AUTH_PREFIX_PATH}/login-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-1")
    ),
  },
  {
    key: "login-2",
    path: `${AUTH_PREFIX_PATH}/login-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-2")
    ),
  },
  {
    key: "register-1",
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-1")
    ),
  },
  {
    key: "register-2",
    path: `${AUTH_PREFIX_PATH}/register-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-2")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "error-page-1",
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
  {
    key: "error-page-2",
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-2")),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.visit-dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import("views/app-views/apps/dashboard/")),
  },
  {
    key: "profile.password",
    path: `${APP_PREFIX_PATH}/change-password`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/ChangePassword")
    ),
  },
  {
    key: "dashboard.visit-dashboard",
    path: `${APP_PREFIX_PATH}/edit-profile`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/EditProfile")
    ),
  },
  // admin=========>
  {
    key: "user.admins",
    path: `${APP_PREFIX_PATH}/admins/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/admin/AdminList")
    ),
  },
  {
    key: "user.admins.add",
    path: `${APP_PREFIX_PATH}/admin-add`,
    component: React.lazy(() => import("views/app-views/pages/admin/AdminAdd")),
  },
  {
    key: "user.admins.edit",
    path: `${APP_PREFIX_PATH}/admin-edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/admin/AdminAdd")),
  },
  {
    key: "user.admins.profile",
    path: `${APP_PREFIX_PATH}/admin-profile/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/profile/AdminProfile")
    ),
  },


  // User Section Route
  {
    key: "user.users",
    path: `${APP_PREFIX_PATH}/user/list`,
    component: React.lazy(() => import("views/app-views/pages/user/UserList")),
  },
  {
    key: "user.users.add",
    path: `${APP_PREFIX_PATH}/user-add`,
    component: React.lazy(() => import("views/app-views/pages/user/UserAdd")),
  },
  {
    key: "author.authors.edit",
    path: `${APP_PREFIX_PATH}/user/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/user/UserAdd")),
  },
  {
    key: "author.authors.edit",
    path: `${APP_PREFIX_PATH}/user/details/:id`,
    component: React.lazy(() => import("views/app-views/pages/user/UserDetails")),
  },


  ////post////////////
  {
    key: "posts.list",
    path: `${APP_PREFIX_PATH}/posts/list`,
    component: React.lazy(() => import("views/app-views/pages/post/postList")),
  },
  {
    key: "posts.add",
    path: `${APP_PREFIX_PATH}/posts/add`,
    component: React.lazy(() => import("views/app-views/pages/post/PostAdd")),
  },
  {
    key: "posts.edit",
    path: `${APP_PREFIX_PATH}/posts/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/post/PostAdd")),
  },
  // ------------------------ Business-------------------------
  {
    key: "business.list",
    path: `${APP_PREFIX_PATH}/business/list`,
    component: React.lazy(() => import("views/app-views/pages/business/BusinessList")),
  },
  {
    key: "business.add",
    path: `${APP_PREFIX_PATH}/business/add`,
    component: React.lazy(() => import("views/app-views/pages/business/BusinessAdd")),
  },
  {
    key: "business.edit",
    path: `${APP_PREFIX_PATH}/business/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/business/BusinessEdit")),
  },
  {
    key: "business.details",
    path: `${APP_PREFIX_PATH}/business/details/:id`,
    component: React.lazy(() => import("views/app-views/pages/business/BusinessDetails")),
  },

  // ------------------------Collection-------------------------
  {
    key: "collection.list",
    path: `${APP_PREFIX_PATH}/collection/list`,
    component: React.lazy(() => import("views/app-views/pages/collection/CollectionList")),
  },
  {
    key: "collection.add",
    path: `${APP_PREFIX_PATH}/collection/add`,
    component: React.lazy(() => import("views/app-views/pages/collection/CollectionAdd")),
  },
  {
    key: "collection.edit",
    path: `${APP_PREFIX_PATH}/collection/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/collection/CollectionAdd")),
  },
  //----------------- Category--------------------------------
  {
    key: "category.list",
    path: `${APP_PREFIX_PATH}/category/list`,
    component: React.lazy(() => import("views/app-views/pages/category/CategoryList")),
  },
  {
    key: "category.add",
    path: `${APP_PREFIX_PATH}/category/add`,
    component: React.lazy(() => import("views/app-views/pages/category/CategoryAdd")),
  },
  {
    key: "category.edit",
    path: `${APP_PREFIX_PATH}/category/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/category/CategoryAdd")),
  },
 //-----------------------------------------------------------
  {
    key: "media.list",
    path: `${APP_PREFIX_PATH}/media/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/media/MediaList")
    ),
  },
  {
    key: "cms.settings.list",
    path: `${APP_PREFIX_PATH}/site-settings`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/SiteSetting")
    ),
  },

  {
    key: "cms.emails.list",
    path: `${APP_PREFIX_PATH}/email-templates/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/email/emailList")
    ),
  },
  {
    key: "cms.emails.edit",
    path: `${APP_PREFIX_PATH}/email-templates/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/email/EmailAdd")),
  },
  {
    key: "cms.emails.add",
    path: `${APP_PREFIX_PATH}/email-templates/add`,
    component: React.lazy(() => import("views/app-views/pages/email/EmailAdd")),
  },
  {
    key: "cms.faqs.list",
    path: `${APP_PREFIX_PATH}/faqs/list`,
    component: React.lazy(() => import("views/app-views/pages/faq/faqList")),
  },
  {
    key: "cms.faqs.edit",
    path: `${APP_PREFIX_PATH}/faqs/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/faq/FaqAdd")),
  },
  {
    key: "cms.faqs.add",
    path: `${APP_PREFIX_PATH}/faqs/add`,
    component: React.lazy(() => import("views/app-views/pages/faq/FaqAdd")),
  },
  {
    key: "cms.pages.list",
    path: `${APP_PREFIX_PATH}/pages/list`,
    component: React.lazy(() => import("views/app-views/pages/page/pageList")),
  },
  {
    key: "cms.pages.edit",
    path: `${APP_PREFIX_PATH}/pages/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/page/PageAdd")),
  },
  {
    key: "cms.pages.add",
    path: `${APP_PREFIX_PATH}/pages/add`,
    component: React.lazy(() => import("views/app-views/pages/page/PageAdd")),
  },
];
