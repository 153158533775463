import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import topic from "./slices/topicSlice";
import lang from "./slices/langSlice";
import profile from "./slices/profileSlice"

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    lang,
    topic,
    profile,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
